.confirm-modal {
    text-align: center;
}

.confirm-modal.nowrap {
    white-space: nowrap;
}
  
.confirm-modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
}

.confirm-modal .ant-modal {
    display: inline-block;
    vertical-align: middle;
    top: 0;
    text-align: left;
}

.confirm-modal .ant-modal-body {
    padding: 0px;
}

.confirm-modal .ant-modal-content {
    padding: 24px;
    border-radius: 0px;
}

@media(min-width: 576px){
    .confirm-modal .ant-modal-content {
        padding: 40px 48px;       
    }
}

.confirm-modal .ant-modal-footer {
    border-top: 1px solid transparent;
    padding: 0px;
    margin-top: 40px;
}

.confirm-modal .ant-modal-footer > div {
    text-align: center;
}

.confirm-modal .ant-model-content {
    box-shadow: 0 12px 24px 0 rgba(117,119,123,0.3);
}

.confirm-modal.no-cancel .ant-modal-footer .ant-btn:first-child {
    display: none;
}

.confirm-modal .custom-title {
    margin-bottom: 20px;
}
