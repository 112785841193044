
.chip-list {
    display: flex;
    flex-wrap: wrap;
    .chip {
        display: flex;
        align-items: center;
        margin: 0 6px 12px 0;
        padding: 6px 16px;
        background: #ecedee;
        border-radius: 16px;
        font-size: 12px;
    }
    .chip-close{
        cursor: pointer;
        margin-left: 6px;
        color: #cfd0d2;
        &:hover{
            color: #3c3d3f;
        }
        &.disabled{
            cursor: not-allowed;
            &:hover{
                color: #cfd0d2;
            }
        }
    }
}



@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;