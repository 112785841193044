.cp-page {
    /* display: inline-block; */
    text-align: left;

    padding: 20px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    margin: 0 -20px 0 -20px;
}

@media (min-width: 768px) {
    .cp-page {
        min-width: 560px;
        padding: 40px;
        margin: 0 auto 25px auto;
    }
}

.cp-page.small {
    width: 440px;
}

.cp-page-footer {
    margin-top: 2em;
}

/* .cp-page .ant-btn.ant-btn-lg {
    min-width: 166px;
} */
.cp-page .ant-btn-primary.ant-btn-lg {
    font-weight: 500;
}

/* .cp-page-footer .ant-btn.ant-btn-lg {
    min-width: 166px;
} */
