.urls-input-container {
    position: relative;
    .ant-input,.ant-btn{
        border-radius: 6px;
    }
    .ant-input-affix-wrapper {
        .ant-input-suffix {
            right: 8px;
        }

        .ant-input:not(:last-child) {
            padding-right: 70px;
        }
    }

    .url-input.validating {
        .ant-input {
            color: #808286;
        }

        .ant-btn {
            opacity: 0.6;
        }
    }

    .btn-add {
        border-radius: 100px;
        color: #346aff;
        border-color: #346aff;
        font-size: 12px;
        font-weight: bold;
        padding: 0px 13px;
    }

    .btn-upload-screenshot {
        display: flex;
        align-items: center;
        gap: 4px;
        
    }

    .validating-mask {
        position: absolute;
        z-index: 10;
        left: 0px;
        top: 0px;
        right: 0px;
        height: 40px;
        background-color: transparent;
        opacity: 0.3;
        border-radius: 8px;
    }

    .error-message {
        margin-top: 10px;
        color: #f56112;
        padding: 0px;
        font-size: 12px;
    }

    .url-list {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 15px;
        color: #808286;
        .url-pretext{
            margin-right: 4px;
            color: #346AFF;
            font-size: 12px;
        }
        .url-item {
            display: flex;
            align-items: center;
            &:not(:last-child){
                margin-bottom: 8px;
            }
            
            /* border-top: 1px solid #ecedee; */

            

            .url-address-box{
                display: flex;
                align-items: center;
                min-height: 30px;
                padding: 6px 12px 6px 6px;
                background: #F1F6FF;
                border-radius: 2px;

                .url-address {
                    word-break: break-all;
                    margin-right: 5px;
                    color: #808286;
                    text-decoration: none;
                }

                .btn-remove {
                    cursor: pointer;
    
                    &:hover {
                        color: #346AFF !important;
                    }
                    &[disabled] {
                        color: #808286 !important;
                        cursor: not-allowed;
                    }
                }
            }

            .ant-input-sm {
                height: 28px;
                padding-left: 5px;
                padding-right: 25px;
                font-size: 12px;

                &::placeholder {
                    color: #d3d3d3;
                    font-size: 12px;
                }
            }
        }

        .url-item-actions {
            flex: 0 0 82px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-left: 8px;
        }
    }
}

@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;