.top-products {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .view-more {
            color: #346aff;
            font-size: 16px;
            &:hover {
                color: #346aff;
            }            
        }
    }

    &+.top-products {
        margin-top: 20px;
    }
}
@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;