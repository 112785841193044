.step-wizard {
    position: relative;
    min-height: 400px;
}

.step {
    width: 100%;
    animation-duration: 0.6s;
}

.hide {
    display: none;
}