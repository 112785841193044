.sub-id-select-container{
    position: relative;
    .sub-id-select-dropdown{
        left: auto !important;
        right: 9px !important;
    }
}
.ant-select.sub-id-select {
    color: #008C00;
    
    .ant-select-selection--multiple {
        .ant-select-selection__choice {
            color: currentColor;
            background: transparent;
            border: 0 none;
            padding: 0;
        }
    }
    .ant-select-search {
        opacity: 0;
    }
}
@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;