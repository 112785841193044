.image-modal .ant-modal-body {
    min-height: 300px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .image-modal .ant-modal-body {
        > img {
            max-width: 100% !important;
        }
    }
}

@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;