.consent-item {
    display: table;
    width: 100%;
}

.consent-item .inner-wrapper {
    /* border: 1px solid #d3d3d3; */
    /* border-radius: 8px; */
    padding: 10px 0px;
    position: relative;
    display: table-cell;
    vertical-align: middle;
}

.consent-item .title {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    right: 70px;
    left: 35px;
    text-overflow: ellipsis;
    cursor: default;
    font-weight: 300;
}

.consent-item .btn-detail {
    float: right;
    border-radius: 100px;
    font-size: 12px;
    margin-top: -2px;
    color: #3c3e41;
    font-weight: 500;
}

/* .consent-detail-modal .detail-description {
    overflow: auto;
    min-height: 300px;
    max-height: 450px;
} */

.consent-detail-modal .ant-modal-content {
    border-radius: 0px;
}

.consent-detail-modal .ant-modal-header {
    border-bottom: none;
    padding: 36px 40px;
}

.consent-detail-modal .ant-modal-title {
    font-size: 21px;
}

.consent-detail-modal .ant-modal-body {
    padding: 0px 40px 40px 40px;;
}

.consent-detail-modal .ant-modal-footer {
    text-align: center;
    border-top: none;
    padding-bottom: 40px;
}

.consent-detail-modal .detail-scroll-thumb {
    border-radius: 4px;
    background-color: #808286;
}