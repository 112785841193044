.notification-bell {
    display: flex;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;

    .ant-badge-count {
        height: 18px;
        line-height: 18px;
        transform: translate(65%, -50%);
    }

    .ant-badge-multiple-words {
        padding: 0px 5px;
    }
}

.message-list-overlay {
    box-shadow: 0 3px 12px 0 rgba(117, 119, 123, 0.17);
    border-radius: 8px;
    width: 340px;
    background-color: white;
    font-size: 12px;
    letter-spacing: -0.4px;
    .message-header{
        display: flex;
        padding: 16px;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
        .message-header-title{
            flex: auto;
            text-align: left;
            font-weight: 700;
            font-size: 14px;
        }
    }
    .message-footer{
        padding: 12px;
        border-top: 1px solid #e8e8e8;
        text-align: center;
    }

    // .ck-content{
    //     max-height: 400px;
    //     overflow-y: scroll;
    // }
}
@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;