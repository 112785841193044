.custom-dataset.ant-modal-wrap {
    // z-index: 1100;
    .right-border {
        border-right: 1px solid #d3d3d3;
    }

    .search-section {
        // padding: 28px 10px 20px 10px;
        border-radius: 8px;
        // background-color: #f7f7f7;
        margin-bottom: 20px;

        .delivery-filter {
            margin-top: 16px;
        }      
        
    }


    .search-result-scroll-container {
        max-height: 265px;
        overflow-y: auto;
        overflow-x: hidden;   
        
        @media (min-width: 360px){
            .product-item {        
                min-height: auto;
            }
        }
    }

    .product-search-list .search-result-pager {
        margin-top: 15px;
    }

    @media(max-width: 576px) {
        .affiliate-modal .ant-modal-footer .ant-btn {
            min-width: 110px;
        }
    }
}

.dataset-product-list {
    // height: 126px;
    padding: 8px;
    border-radius: 8px;
    background-color: #f7f7f7;
    display: flex;
    flex-wrap: wrap;
    // overflow-x: auto;
    overflow-y: auto;
    max-height: 350px;

    &.flex-center {
        justify-content: center;
    }

    .dataset-product {
        position: relative;
        padding: 3px;

        img {
            height: 120px;
        }

        .remove-btn {
            position: absolute;
            top: 5px;
            right: 5px;
            height: 20px;
            width: 20px;
            border-color: transparent;
            opacity: 0;
            cursor: pointer;

            svg {
                font-size: 20px;
            }
        }

        &:hover, &.mobile {
            .remove-btn {
                opacity: 1;
                transition: linear 0.2s;
                display: inline-block;
            }
        }
    }

}
@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;