.nav-menu.ant-menu-horizontal {
    border-bottom: none;
    position: absolute;
    left: 228px;
    line-height: 66px;
}

.nav-menu.ant-menu-horizontal .ant-menu-submenu svg {
    vertical-align: middle;
}

.nav-submenu.ant-menu-submenu-popup {
    border-radius: 8px;
}
.nav-submenu.ant-menu-submenu-vertical .ant-menu-submenu-title {
    padding-right: 42px;
}

.nav-menu-item .ant-badge .ant-badge-dot {
    right: -6px;
}

/* .ant-drawer.hamburger-drawer {
    top: 68px;
}
.ant-drawer.hamburger-drawer .ant-drawer-content{
    padding-bottom: 68px;
} */

.hamburger-nav-menu-vertical {
    cursor: pointer;
}
.hamburger-nav-menu-vertical svg {
    vertical-align: middle;
}
.nav-menu-vertical.ant-menu-vertical {
    border: 0 none;
}
.nav-menu-vertical.ant-menu-vertical .ant-menu-item {
    height: auto;
    line-height: 24px;
}
.nav-menu-vertical.ant-menu-vertical > li:not(:last-child) {
    border-bottom: 1px solid #ecedee;
    padding-bottom: 24px;
}
.nav-menu-vertical.ant-menu-vertical > li:not(:first-child) {
    margin-top: 24px;
}

.nav-menu-vertical a {
    font-size: 16px;
}
.nav-menu-vertical .ant-menu-item-group-title {
    margin-bottom: 8px;
    padding-left: 0;
    padding-bottom: 0;
    color: #808286;
    font-size: 12px;
}
.nav-menu-vertical.ant-menu-vertical .ant-menu-item {
    padding-left: 0;
}

.nav-menu-vertical .ant-menu-item-group .ant-menu-item-group .ant-menu-item-group-title {
    font-size: 16px;
    color: #3c3d3f;
}

.nav-menu-vertical .ant-menu-item-group .ant-menu-item-group .ant-menu-item-group-list {
    padding-left: 1.5em;
}
