.document-uploader {

}

.document-uploader .title {
    font-size: 16px;
    font-weight: bold;
}

.document-uploader .align-left {
    text-align: left;
}

.document-uploader .align-right {
    text-align: right;
}

.document-uploader .btn-upload {
    cursor: pointer;
}

.document-uploader .btn-upload svg {
    vertical-align: text-bottom;
    margin-right: 6px;
}

.document-uploader .thumb-area {
    margin-top: 20px;
}

.document-uploader .ant-upload.ant-upload-drag {
    border: 0px;
    height: 420px;
    padding: 16px;
}

.document-uploader .ant-upload .btn-plus {
    color:#CFD0D2;
}

.document-uploader .ant-upload:hover .btn-plus {
    color: rgba(0, 0, 0, 0.65);
}

.document-uploader .thumb-area .img-preview {
    width: 100%;
    height: 388px;
}