.app-header {
    height: 68px;
    /* width: 1440px; */
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    background-color: white;
    position: relative;
}

.header-brand {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
    .header-brand {
        left: 60px;
        transform: translate(0, -50%);
    }
}

.hamburger-nav-menu-vertical {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
}

.header-toolbar {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
}

.header-toolbar .user-toolbar {
    display: table-cell;
    vertical-align: middle;
}
.header-toolbar .login-signup {
    display: table-cell;
    vertical-align: middle;
}
