.unselectable-input, 
.unselectable-textarea {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  user-select: none; 
  padding: 6px 11px;
  min-width: 120px; 
  width: 100%;
  letter-spacing: normal;

  &:hover {
    border-color: #40a9ff;
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: #f5f5f5;
    border-color: #dfe0e2;
    color: rgba(0, 0, 0, 0.25);
  }

  &.large {
    height: 40px;
  }
}

.unselectable-input {
    display: inline-flex;
    align-items: center;
}

.unselectable-textarea {
  pre {
    font-size: 14px;
    font-family: NotoSansCJKKr,-apple-system,Roboto,Helvetica,Arial,sans-serif;
  }
}
@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;