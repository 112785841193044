.monthly-aggregation-panel {
    background-color: #fafafa;
    border-radius: 8px;
    min-height: 300px;
    padding: 34px 24px;
}

.monthly-aggregation-panel .item-line {
    line-height: 31px;
    margin-bottom: 27px;
}

.monthly-aggregation-panel .item-key {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.4px;
}

.monthly-aggregation-panel .item-value {
    font-size: 21px;
    font-family: Roboto, sans-serif;
    letter-spacing: -0.2px;
}

.monthly-aggregation-panel hr {
    border: 1px solid #dfe0e2;
    margin-bottom: 15px;
}

.monthly-aggregation-panel .full-report-link {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.4px;
}

.monthly-aggregation-panel .full-report-link svg {
    vertical-align: text-bottom;
}