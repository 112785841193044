.best-products-carousel {
    position: relative;
    /* min-height: 278px; */
    /* width: 1160px; */
}

.best-products-carousel .page-navigator {
    display: inline-block;
    height: 58px;
    width: 58px;
    border-radius: 50%;
    background-color: #141414;
    color: white;
    position: absolute;
    top: 50%;
    margin-top: -29px;
    border-color: transparent;
    z-index: 1;
}
.best-products-carousel .page-navigator:hover{
    background-color: #fff;
}

.best-products-carousel .page-navigator > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
}

.best-products-carousel .previous-page {
    left: -18px;
}

.best-products-carousel .next-page {
    right: -18px;
}

.best-product-carousel-page {
    /* overflow: hidden; */
    /* padding:0 2px; */
    margin: 10px 0;
    display: flex !important;
    justify-content: space-around;
}

.best-product-carousel-page .product-item, .best-product-list .product-item{
    
    background-color: white;
    
}



/* .best-product-carousel-page .product-item:not(:last-child) {
    margin-right: 16px;
} */

.best-product-carousel-page .product-item.view-more {
    position: relative;
    color: #346aff;
    font-weight: bold;
    cursor: pointer;
}

.best-product-carousel-page .product-item.view-more:hover {
    color: #455ea4;
}

.best-product-carousel-page .product-item.view-more > div {
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    text-align: center;
}