.sns-sharer{
    display: inline-flex;
    align-items: center;
    .sns-sharer-button{
        cursor: pointer;
        width: 30px;
        height: 30px;
        &:not(:last-child){
            margin-right: 4px;
        }
    }
}
@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;