.lt-ie11 .lt-ie11-msg {
    display: block;
    color: #f56112;
    text-align: center;
}

.lt-ie11 .header-container {
    position: relative;
}

.lt-ie11 .workspace-container {
    margin-top: 0px;
}

html,
body {
    flex: auto;
    display: flex;
    flex-flow: column;
    height: auto;
    min-height: 100%;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    html,
    body {
        height: 100%;
    }
}

:focus {
    outline: 0 none;
}

body {
    margin: 0px auto;
    padding: 0;
    background-color: #f7f7f7;
    /* min-width: 1440px; */
    overflow-x: hidden;
}

#root {
    flex: auto;
    display: flex;
}

.lang {
    word-break: keep-all;
}
.lang {
    flex: auto;
    display: flex;
    letter-spacing: -0.4px;
}
.lang > div,
.lang > div {
    flex: auto;
    display: flex;
    flex-flow: column;
}

.color-primary {
    color: #346aff;
}
.color-accent {
    color: #008c00;
}
.color-danger {
    color: #f56112;
}
.color-purple {
    color: #9c27b0;
}

.dim {
    opacity: 0.8;
}

.bg-grey {
    background-color: #f7f7f7;
}

.bg-dark-grey {
}

.line-through {
    text-decoration: line-through;
}

.text-secondary {
    font-size: 0.8em;
}

.text-bold {
    font-weight: bold;
}

.header-container {
    width: 100%;
    text-align: center;
    background-color: white;
    position: fixed;
    z-index: 999;
    top: 0px;
    box-shadow: 0 2px 12px 0 rgba(20, 20, 20, 0.1);
    height: 68px;
}

.workspace-container {
    flex: auto;
    display: flex;
    position: relative;
    text-align: center;
    margin-top: 68px;
    background-color: white;
    /* overflow-x: hidden; */
    overflow: hidden; /* carousel full-screen css */
}

.workspace {
    flex: auto;
    display: flex;
}

.workspace .page-outlet {
    flex: auto;
    display: flex;
    flex-flow: column;
    text-align: left;
}

.footer-container {
    background-color: #5c3869;
}

.align-left {
    text-align: left !important;
}
.align-center {
    text-align: center !important;
}
.align-right {
    text-align: right !important;
}
.clickable {
    cursor: pointer;
}

.mt05em {
    margin-top: 0.5em;
}

.mt1em {
    margin-top: 1em;
}

.mt4 {
    margin-top: 4px;
}

.mt8 {
    margin-top: 8px;
}

.mt16 {
    margin-top: 16px;
}

.mt20 {
    margin-top: 20px;
}

.mt24 {
    margin-top: 24px;
}

.mt32 {
    margin-top: 32px;
}

.mb4 {
    margin-bottom: 4px;
}

.mb6 {
    margin-bottom: 6px;
}

.mb8 {
    margin-bottom: 8px;
}

.mb16 {
    margin-bottom: 16px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb24 {
    margin-bottom: 24px;
}

.mb32 {
    margin-bottom: 32px;
}

.mb40 {
    margin-bottom: 40px;
}

.mt40 {
    margin-top: 40px;
}

.ml05em {
    margin-left: 0.5em;
}

.ml1em {
    margin-left: 1em;
}
.mr2 {
    margin-right: 2px;
}
.mr4 {
    margin-right: 4px;
}
.mr6 {
    margin-right: 6px;
}
.mr8 {
    margin-right: 8px;
}
.mr05em {
    margin-right: 0.5em;
}

.mr1em {
    margin-right: 1em;
}

.mb1em {
    margin-bottom: 1em;
}

.button-bar {
    button:not(:last-child) {
        margin-right: 8px;
    }

    .ant-btn:not(:last-child) {
        margin-right: 8px;
    }

    .ant-btn.icon-btn {
        display: inline-flex;
        align-items: center;
    }

    .ant-btn.ant-btn-circle {
        justify-content: center;
        height: 36px;
        width: 36px;
    }
}

.btn-rounded {
    border-radius: 6px;
    &[disabled],
    &[disabled]:hover {
        background: #ccc;
        border-color: #ccc;
        color: #999;
    }
}

.icons-bar svg:not(:last-child) {
    margin-right: 1.5em;
}

h1.page-title {
    margin-top: 0.5em;
}
h2.form-title {
    margin-bottom: 1em;
}

.page-title-secondary {
    margin-top: 18px;
    min-height: 12px;
    margin-bottom: 14px;
    font-weight: 400;
    font-size: 24px;
    .text-secondary {
        margin-left: 6px;
        color: #2f9e51;
        font-size: 14px;
    }
}

@media (max-width: 575px) {
    h1 {
        font-size: 21px;
    }
    h2 {
        font-size: 21px;
    }
}

.ft-sub-headline {
    font-size: 16px;
}
.ft-sub-headline-bold {
    font-size: 21px;
    font-weight: bold;
}
.ft-body {
    font-size: 14px;
    letter-spacing: -0.4px;
    font-weight: 300;
}
.ft-bold {
    font-weight: bold;
}
.ft-bold-500 {
    font-weight: 500;
}
.ft-12 {
    font-size: 12px;
}
.ft-16 {
    font-size: 16px;
}
.lh-24 {
    line-height: 24px;
}
.fw-100 {
    font-weight: 100;
}
.fw-300 {
    font-weight: 300;
}
.fw-700 {
    font-weight: 700;
}

.va-tb {
    vertical-align: text-bottom;
}

.hidden {
    display: none;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.emotion-line {
    margin-bottom: 24px;
}

.is-marginless {
    margin: 0 !important;
}

.is-paddingless {
    padding: 0 !important;
}

.is-list-style-none {
    list-style: none;
}
.is-flex-row {
    display: flex;
    align-items: center;
}
.is-flex-column {
    display: flex;
    flex-direction: column;
}
.is-flex {
    flex: auto;
}
.is-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dark-grey-box {
    padding: 10px;
    background: #ebedf0;
    border-radius: 2px;
    font-size: 12px;
}

.modal .ant-modal {
    color: #3c3d3f !important;
}
.modal.no-padding .ant-modal-body {
    padding: 0;
}
.modal-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;
}

.modal-body {
    margin-bottom: 24px;
}
.modal-footer {
    margin-top: 36px;
    text-align: center;
}
.modal-footer button + button {
    margin-left: 12px;
}
.modal-footer button {
    min-width: 98px;
}

.modal .ant-modal-content {
    border-radius: 0px;
    box-shadow: 0 12px 24px 0 rgba(117, 119, 123, 0.3);
}
@media (min-width: 576px) {
    .modal .ant-modal-body {
        padding: 40px 48px;
    }
}

.pane {
    margin-bottom: 24px;
    padding: 24px;
    background: #fff;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
.pane .pane-title {
    margin-bottom: 24px;
}

.large-title {
    font-size: 28px;
}

.page-spin-container {
    display: flex;
    flex: auto;
}
.page-spin-container .ant-spin-container {
    display: flex;
    flex: auto;
    flex-flow: column;
}
.page-spin-container .ant-spin-container > .ant-table-pagination {
    align-self: flex-end;
}

.menu-horizontal.ant-menu-horizontal {
    border-bottom: 0 none;
}

.menu-horizontal .ant-menu-item {
    color: #808286;
}

.menu-horizontal .ant-menu-item.ant-menu-item-selected {
    color: inherit;
    font-weight: bold;
}

.code-generation-container {
    margin-bottom: -20px;
    padding: 40px 0 20px 0px;
}

@media (min-width: 576px) {
    .code-generation-container {
        margin-bottom: -40px;
        padding: 40px 0 40px 0px;
    }
}

.code-generation-content {
    display: flex;
}

.code-generation {
    background-color: #f7f7f7;
    padding: 40px 0 5px 0;
    overflow: hidden;
}

.code-preview {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 440px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(117, 119, 123, 0.1);
    padding: 20px 24px 40px 24px;
    margin: 0 20px 0 0;
}

.code-preview .code-container {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.code-viewer .code-container {
    min-height: 160px;
}

@media (max-width: 1199px) {
    .code-generation-content {
        flex-direction: column;
    }
    .code-preview {
        min-height: 240px;
        margin: 0 0 20px 0;
    }
}

.tooltip-icon {
    cursor: pointer;
    display: flex;
    margin: 4px;
}

.link-generation-steps-indicator {
    display: inline-block;
    margin-top: 27px;
    margin-bottom: 20px;
}
.search-bar-banner {
    display: none;
}
@media (min-width: 992px) {
    .search-bar-banner {
        display: block;
    }
}

// /*
//   Not to stick the header is window is less than 1440px
//   because fixed position calculates based on viewport, which could cause displaying issue for a fixed width of header
// */
// @media(max-width:1439px) {
//   body {
//     overflow-x: auto;
//   }

//   /* .header-container {
//     position: static;
//   }

//   .workspace-container {
//     margin-top: 0px;
//   } */
// }

.dated-report.ant-tabs .ant-tabs-top-bar {
    margin-bottom: 32px;
}

.ant-select.light-select {
    font-size: 12px;
    font-weight: bold;
    .ant-select-selection {
        border: 0 none;
    }
    .ant-select-arrow {
        color: currentColor;
    }
    &.ant-select-focused {
        .ant-select-selection {
            box-shadow: none;
        }
    }
}

.action-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;

    .action:not(:last-child) {
        margin-right: 12px;
        > label {
            color: #808286;
        }
    }
}

.feathericon {
    vertical-align: -0.2rem;
}

.expand-arrow {
    svg {
        transform-origin: 50% 50%;
        transition: transform 0.3s;
    }
    &.opened {
        svg {
            transform: rotate(180deg);
        }
    }
}

.ant-modal-confirm.affiliate-confirm {
    .ant-modal-content {
        padding: 24px;

        .ant-modal-body {
            padding: 0;
        }

        .ant-modal-confirm-body {
            > i.anticon-question-circle {
                display: none;
            }

            .ant-modal-confirm-title {
                font-size: 28px;
                margin-bottom: 20px;
            }

            .ant-modal-confirm-content {
                margin: 0;
            }
        }

        .ant-modal-confirm-btns {
            float: none;
            text-align: center;
        }
    }

    @media (min-width: 576px) {
        .ant-modal-content {
            padding: 40px 48px;
        }
    }
}

@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;