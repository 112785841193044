.registration-flow .wizard-title {
    margin-top: 20px;
    min-height: 12px;
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 24px;
    .text-secondary {
        margin-left: 6px;
        color: #2F9E51;
        font-size: 14px;
    }
}

.registration-flow div[class^='step-wizard'] {
    min-height: 140px;
}
.registration-flow .buttons-bar {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
/* @media(max-width: 440px){
    .registration-flow .buttons-bar {
        flex-direction: column;
    }
} */
.registration-flow .buttons-bar div {
    margin: 6px;
}

.affiliate-type .ant-radio-wrapper span.ant-radio + * {
    padding-right: 0px;
}


.sign-up-successful-modal{
    .ant-modal-content{
        border-radius: 6px;
        .title{
            margin-bottom: 24px;
            text-align: center;
            font-size: 18px;
        }
    }
}
@media(max-width: 480px){
    
    .sign-up-successful-modal{
        .ant-modal-content{
            .title{
                font-size: 14px;
            }
        }
    }
}
@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;