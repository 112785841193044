.chrome-extension {
    .chrome-ext-banner {
        min-height: 120px;
        background-color: #e2f2f0;
        border-radius: 8px;
        margin-bottom: 40px;

        .keys-description {
            padding: 32px;
            font-size: 14px;
            min-height: 190px;

            @media(max-width: 991px) {
                padding: 20px;
                min-height: auto;
            }
        }

        .keys-image  {
            padding-right: 25px;
            align-self: flex-end;
            img {
                width: 100%;           
            }

            @media(max-width: 991px) {
                padding-right: 0px;
                padding-bottom: 15px;
            }
        }
      
    }
    

    .pane {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 260px;

        .download-item {           
            text-align: center;         

            @media(min-width: 996px) {
                width: 350px;
            }

            .title {
                font-size: 16px;
                margin-bottom: 16px;
            }

            .ant-btn-lg {
                min-width: 250px;
                display: inline-flex;
                align-items: center;

                img {
                    width: 32px;
                    height: 32px;
                    margin-right: 8px;
                }

                svg {
                    margin-left: 8px;
                }
            }

            
            @media(min-width: 992px) {              
                &:not(:last-child) {
                    border-right: 1px solid #d3d3d3;
                }                    
            }
            @media(max-width: 991px) {
                &:not(:last-child) {
                    margin-bottom: 2em;
                }
            }
        }
    }
}

@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;