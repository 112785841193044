.html-code-viewer {
    display: flex;
    flex-flow: column;
    flex: 1;
    height: 100%;
    padding: 20px 24px;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(117, 119, 123, 0.1);
}

.html-code-viewer .title {
    margin-right: 2em;
}

.html-code-viewer .title-tip {
    display: block;
    font-size: 12px;
    font-weight: normal;
}
@media (min-width: 576px) {
    .html-code-viewer .title-tip {
        display: inline;
    }
}
.html-code-viewer .code-container {
    display: flex;
    flex: auto;
}

.html-code-viewer textarea.code,
.html-code-viewer .unselectable-textarea.code {
    margin-bottom: 40px;
    padding: 12px;
    word-break: break-all;
}

.html-code-viewer .ant-btn {
    font-size: 14px;
    font-weight: 500;
}

.html-code-viewer .ant-btn > svg {
    vertical-align: middle;
    margin-right: 5px;
}
.html-code-viewer .ant-btn > span {
    vertical-align: middle;
}
