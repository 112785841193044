
.root{
    display: flex;
    align-items: center;
}
.indicator {
    flex: none;
    width: 16px;
    height: 16px;
    font-size: 11px;
    line-height: 16px;
    background-color: #4d4d4d;
    border-radius: 8px;
    color: #fff;
    text-align: center;
    &.current {
        background-color: #346AFF;
    }
}
.divider{
    flex: 1;
    height: 1px;
    margin: 0 4px;
    background-color: #c8cacc;
}
