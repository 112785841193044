.landing-page {
    background-color: white;
}

.landing-page .affiliate-banner {

    overflow: hidden;
    position: relative;
    /* height: 300px; */
    margin-bottom: 80px;
}

.landing-page .affiliate-banner img {
    position: relative;
    left: 50%;
    width: 1440px;

    margin-left: -720px;
}

.landing-page .affiliate-video {
    margin-bottom: 8px;
    text-align: center;
}

.landing-page .affiliate-video video{
    width: 100%;
    vertical-align: middle;
}

.landing-page .affiliate-banner-mobile {
    height: 102px;
    margin-bottom: 60px;
    background: #008C00;
    text-align: center;
}
.landing-page .affiliate-banner-mobile img{
    width: 100%;
    max-width: 360px;
}

.landing-page .affiliate-banner .btn-signup{
    display: flex;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    justify-content: center;
    align-items: center;
    
    font-size: 20px;
}

.landing-page .affiliate-slogan {
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    width: 100%;
    text-align: center;
}

.landing-page .affiliate-slogan h1 {
    font-size: 44px;
    font-weight: bold;
    line-height: 56px;
    letter-spacing: -0.4px;
    padding: 0px 40px;
}
@media(max-width:575px){
    .landing-page .affiliate-slogan h1 {
        font-size: 32px;
    }
}

.landing-page .button-bar button {
    font-size: 16px;
    font-weight: 300;
}

.landing-page .question-title {
    font-size: 21px;
    margin-bottom: 30px;
}

@media(min-width:576px){
    .landing-page .question-title{
        font-size: 28px;
        margin-bottom: 50px;
    }
}
.landing-page .why-guide{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-page .why-guide .ant-card {
    max-width: 300px;
}

.landing-page .why-guide .ant-card-cover {
    text-align: center;
}

.landing-page .why-guide .ant-card-cover img {
    height: 200px;
    width: 200px;
    
    display: inline-block;
}

.landing-page .why-guide .ant-card-meta-title {
    font-size: 28px;
    margin-bottom: 12px;
    text-align: center;
}

.landing-page .why-guide .ant-card-body {
    padding-left: 0px;
    padding-right: 0px;
}

.landing-page .why-guide .ant-card-meta-description {
    color: #3c3d3f;
    text-align: left;
    font-weight: 300;
}

.landing-page .divider-row {
    margin-top: 60px;
    margin-bottom: 60px;
}
@media(min-width:1200px){
    .landing-page .divider-row {
        margin-top: 84px;
        margin-bottom: 70px;
    }
}

.landing-page .divider-row .ant-divider-horizontal {
    display: inline-block;
    width: 105px;
    height: 8px;
    min-width: auto;
    border-radius: 6px;
}
.landing-page .home-banner{
    margin-top: 80px;
}
.landing-page .how-guide{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-page .how-guide .ant-card {
    max-width: 360px;
}



.landing-page .how-guide .ant-card-body {
    padding-top: 16px;
    padding-left: 0px;
    padding-right: 0px;
}

.landing-page .how-guide .no-indicator {
    font-size: 21px;
    font-weight: bold;
    margin-right: 12px;
    float: left;
}

.landing-page .how-guide .ant-card-meta-description {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    color: inherit;
}

.landing-page .footer-row {
    margin-top: 80px;
    background-color: #5c3869;
}

.landing-page .cp-row-content{
    padding: 0 20px;
}

@media (min-width: 1440px){
    .landing-page .cp-row-content{
        padding: 0 140px;
    }
}

@media(min-width:1200px){
    .landing-page .footer-row{
        margin-top: 150px;
    }
}

@media(min-width:1200px){
    .landing-page .how-guide{
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start
    }

}
@media(min-width:1200px){
    .landing-page .why-guide{
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start
    }
}
@media(max-width:1199px){
    .landing-page .why-guide .ant-card{
        max-width: 360px;
        display: flex;
    }
    .landing-page .why-guide .ant-card:not(:last-child){
        margin-bottom: 24px;
    }
    .landing-page .why-guide .ant-card-meta-title{
        font-size: 16px;
        margin-bottom: 6px;
        text-align: left;
    }
    .landing-page .why-guide .ant-card-cover img {
        height: 100px;
        width: 100px;
        margin: 10px;
    }
    .landing-page .why-guide .ant-card-body{
        padding-top: 10px;
    }
    .landing-page .why-guide .ant-card:nth-child(2) .ant-card-cover{
        order: 2;
    }
}
