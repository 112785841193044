.login-signup .btn-link:not(:last-child) {
    margin-right: 2em;
}

.login-signup .btn-link {
    border: none;
    padding: 0px;
    height: auto;
    font-weight: bold;
}

.ant-dropdown-menu.toolbar-help, 
.ant-dropdown-menu-submenu.toolbar-help-submenu > .ant-dropdown-menu {
    min-width: 160px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-dropdown-menu.toolbar-help .ant-dropdown-menu-item.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu.toolbar-help-submenu .ant-dropdown-menu-item.ant-dropdown-menu-item-selected
{
    background-color: transparent;
    
    > a {
        color: #008C00 !important;
        font-weight: bold;
        background-color: transparent;
    }
}

@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;