
.messages{
    
    text-align: left;
    .title{
        display: flex;
        gap: 6px;
        align-items: center;
    }
    .actions {
        display: flex;
        padding-top: 12px;
        font-size: 12px;
        .date{
            flex: auto;
            color: rgba(0,0,0,0.5);
        }
    }
    .empty{
        padding: 16px;
        text-align: center;
        color: rgba(0,0,0,0.25);
        font-size: 14px;
    }
    .panel{
        background: #f3f6ff;
        &:global(.ant-collapse-item){
            border: 0 none;
        }
        &:not(:last-child){
            border-bottom: 1px solid #e8e8e8;
        }
        &:global(.ant-collapse-item-active){
            background: #ebeef7;
        }
    }
    :global(.ck-content){
        max-height: 400px;
        overflow-y: scroll;
    }
}
