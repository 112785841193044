.performant-product-title-bar{
    display: flex;
    .performant-product-actins{
        display: flex;
        .button-bar{
            margin-right: 8px;
        }
    }
}
@media(max-width:575px){
    .performant-product-title-bar {
        flex-direction: column;
        .performant-product-actins{
            margin-top: 6px;
        }
    }
}
@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;