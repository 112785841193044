.group-display-container {
    position: relative;
}

.group-display-container .group-display-disabled {
    background-color: #f7f7f7;
}

.group-display {
    overflow: hidden;
    margin: 4px 12px;
    position: absolute;
    z-index: 2;
    background-color: white;
    left: 0px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    border-radius: 8px;
    white-space: nowrap;
}