.product-search-list .search-result-header {
    line-height: 36px;
    margin-bottom: 30px;
}

.product-search-list .search-result-header::after {
    content: '';
    display: table;
    clear: both;
}

.product-search-list .search-result-pager {
    text-align: center;
    margin-top: 60px;
}

.product-search-list .loading-more-indicator{
    text-align: center;
}