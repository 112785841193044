.my-account .ant-list-item {
    min-height: 88px;
}
.my-account .ant-list-item .title {
    display: inline-block;
    font-size: 16px;
    color: #3c3d3f;
    line-height: 1;
    font-weight: bold;
    white-space: nowrap;
}

.my-account .ant-list-item .sub-title {
    font-size: 12px;
    color: #808286;
    margin-left: 1.5em;
}

.my-account .edit {
    font-size: 24px;
    color: #346aff;
}

.my-account .ant-btn.ant-btn-sm {
    height: 28px;
}


.my-account .banner {
    position: relative;
    overflow: hidden;
    height: 160px;
    margin-bottom: 40px;
}
.my-account .banner img{
    position: absolute;
    left: 50%;
    margin-left: -720px;
}

.my-account .cp-page{
    width: 100%;
}

