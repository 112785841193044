.confirmation {
    letter-spacing: -0.4px;
}

.confirmation .emotion-line {
    margin-bottom: 10px;
}

.confirmation p {
    font-weight: 300;
}

.confirmation .email {
    font-size: 16px;
    font-weight: bold;
}
