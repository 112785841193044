.widget-instance-management {
    margin-bottom: -40px;

    h2 {
        margin-bottom: 24px;
    }

    .code-viewer {
        margin-bottom: 60px;
    }

    .ant-form-vertical .ant-form-item-label {
        padding-bottom: 6px;
    }

    .ant-form-item-label label {
        font-weight: 300;
        font-size: 12px;
    }

    .widget-params {
        .param-title {
            margin-bottom: 15px;
            font-size: 12px;
            display: block;
        }

        .param-fields {
            padding-left: 10px;

            .ant-form-item.inline-row {
                display: flex;
        
                .ant-form-item-label {
                    margin-right: 0.5em;
                    width: 45px;
        
                    label {
                        line-height: 40px;
                    }
                }
        
                .ant-form-item-control-wrapper {
                    flex-grow: 1;
                }

                &:last-child {
                    margin-bottom: 0px;
                    padding-bottom: 0px;
                }
            }
        }
    }


    .config-preview {
        margin-top: 40px;
        &.code-generated {
            margin-bottom: 20px;
        }
        margin-bottom: 40px;
        
        display: flex;

        @media (max-width: 1199px) {
            flex-direction: column;
            display: block;
        }

        .config-section, .preview-section {
            background-color: white;
            padding: 24px;
            box-shadow: 0 2px 4px 0 rgba(117, 119, 123, 0.1);
        }

        .config-section {   
            min-width: 308px;         
            min-height: 566px;
            margin-right: 24px;
            flex: 1;

            @media (max-width: 1199px) {
                margin-bottom: 24px;
                margin-right: 0px;
                flex: none;
            }
        }

        .preview-section {           
            flex: 3;

           .preview-scrollable > div {
               text-align: center;              
           }
        }
    }
  
}

@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;