.payment-document-fieldset .file-format-tip {
    font-size: 12px;
    margin-bottom: 1em;
}

.payment-document-fieldset .file-format-tip svg {
    vertical-align: text-bottom;
    margin-right: 4px;
}

.payment-document-fieldset .picture-card-uploader .ant-upload-list-picture-card .ant-upload-list-item,
.payment-document-fieldset .picture-card-uploader .ant-upload.ant-upload-select-picture-card {
    width: 228px;
    height: 300px;
}

.payment-document-fieldset .uploader-row .ant-form-item {
    margin-bottom: 0;
    padding-bottom: 0;
}

.payment-document-fieldset .uploader-row .ant-upload.ant-upload-select-picture-card {
    margin: 0;
}
@media(max-width:575px){
    .payment-document-fieldset .uploader-row > div {
        width: 100%;
    }
    
    .payment-document-fieldset .picture-card-uploader .ant-upload-list-picture-card .ant-upload-list-item,
    .payment-document-fieldset .picture-card-uploader .ant-upload.ant-upload-select-picture-card {
        width: 100%;
    }
}