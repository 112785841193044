.root{
    display: flex;
    color: #008C00;
    .icon{
        padding-top: 4px;
        font-size: 12px;
    }
    .text{
        margin-left: 4px;
        font-size: 12px;
    }
}