.ant-select.filter-select {
    color: #008C00;
    
    .ant-select-selection--multiple {
        .ant-select-selection__rendered > ul > li {
            margin-top: 5px;
        }

        .ant-select-selection__choice {
            color: currentColor;
            background: transparent;
            border: 0 none;
            padding: 0;
        }
    }
    .ant-select-search {
        opacity: 0;
    }
}
@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;