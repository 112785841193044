
.rrn {
    display: flex;
    align-items: center;
}
.rrn > span {
    margin: 0 6px;
}
.rrn > input {
    min-width: 0;
}
.rrn .btn-verify {
    min-width: 70px;
    margin-left: 12px;
}