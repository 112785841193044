.product-category .page-title {
    font-size: 16px;
}

.product-category .product-category-list {
    list-style: none;
    border-radius: 8px;
    background-color: #f7f7f7;
    padding: 10px 24px;
    font-size: 16px;
    font-weight: 200;
    letter-spacing: -0.4px;
}

.product-category .product-category-list > div {
   
   height: 48px;
   line-height: 48px;
}

.product-category .product-category-list > div:hover {
    color: #1890ff;
 }

.product-category .product-category-list > div > a {
    color: inherit;
    white-space: nowrap;
}