.ant-form-item {
    transition: none !important;
}
.ant-form-item .has-error.has-feedback .ant-form-item-children-icon {
    color: #f56112;
}
/* .ant-form-item .has-feedback .ant-form-item-children-icon .feedback-icon {
    font-size: 18px;
} */
.ant-form.ant-form-vertical .ant-form-item .has-feedback .ant-form-item-children-icon {
    position: absolute;
    top: auto;
    bottom: 0px;
}
.ant-form.ant-form-vertical .ant-form-item .has-feedback .ant-form-item-children-icon svg {
    margin-top: 5px;
}
.ant-form .custom-help-line {
    position: relative;
}