/* .ant-steps-dot.reg-steps .ant-steps-label-vertical .ant-steps-item {
    max-width: 120px;
} */

.ant-steps-dot.reg-steps .ant-steps-item-icon .ant-steps-icon-dot {
    height: 20px;
    width: 20px;
}

.ant-steps-dot.reg-steps .ant-steps-item-content .ant-steps-item-title {
    font-size: 12px;
}

.ant-steps-dot.reg-steps .ant-steps-item-icon {
    margin-left: 46px;
}

.ant-steps-dot.reg-steps .ant-steps-item-tail {
    margin-left: 56px;
    top: 9px;
}

.ant-steps-dot.reg-steps .ant-steps-item-tail:after {
    height: 1px;
    background-color: #dfe0e2;
}

.ant-steps-dot.reg-steps.ant-steps-label-vertical .ant-steps-item-content {
    width: 112px;
    margin-top: 22px;
    color: #808286;
}

.ant-steps-dot.reg-steps .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background-color: #cfd0d2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-steps-dot.reg-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background-color: #008C00;
}

.ant-steps-dot.reg-steps .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
    color: #008C00;
}