.consent-link {
    .ant-checkbox-wrapper{
        display: flex;
        align-items: center;
        > .ant-checkbox + span{
            flex: auto;
        }
    }
    .consent-link-item{
        display: flex;
        justify-content: space-between;
    }
    .view-document {
        margin-left: 4px;
        text-decoration: none;
        font-size: 12px;
        color: #346AFF;
        a{
            color: #346AFF;
        }
    }
    cursor: pointer;
}

.consent-links .consent-link:not(:last-child) {
    margin-bottom: 8px;
}

@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;