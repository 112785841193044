.open-api {
    text-align: left;
}

.open-api .open-api-banner {
    min-height: 120px;
    background-color: #e2f2f0;
    border-radius: 8px;
    margin-bottom: 40px;
}

.open-api .keys-description {
    padding: 40px;
    font-weight: bold;
    font-size: 14px;
}

.open-api .keys-description > p:not(:last-child) {
    margin-bottom: 10px;
}

.open-api .keys-description > p:last-child {
    margin-bottom: 0px;
}

.open-api .keys-image {
    overflow: hidden;
    text-align: center; 
}

.open-api .keys-image > img {
    width: 100%;
    max-width: 140px;
}

@media(max-width: 1199px) {
    .open-api .keys-description {
        padding-bottom: 0px;
    }

    .open-api .keys-image {
        padding: 10px 40px 0px 40px;
    }
}

.open-api .isolate-button .ant-input::placeholder {
    font-weight: 400;
}

.open-api .isolate-button .ant-btn {
    min-width: 90px;
}

.open-api .isolate-button.ant-input-affix-wrapper .ant-input {
    width: calc(100% - 100px);
    font-size: 14px;
}

.open-api .isolate-button.ant-input-affix-wrapper .ant-input-suffix {
    right: 0px;
}
