.footer-container {
    background-color: #5c3869;
    text-align: center;
}

.app-footer {
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 12px;
    font-weight: 100;
    margin: 0 auto;
    text-align: left;
    /* width: 1440px; */
    width: 100%;
    max-width: 1440px;
    padding: 30px 20px;
}



.app-footer ul{
    display: flex;
    flex-flow: column;
    list-style: none;
    justify-content: space-between;
    padding: 0;
}
.app-footer ul li{
    flex:1 1 auto;
    padding: 5px 0;
}

@media (min-width: 992px){
    .app-footer{
        padding: 45px 140px 35px 140px;        
    }
    .app-footer ul{
        flex-flow: row;
    }
}

.app-footer a {
    color:#fff;
    text-decoration: underline;
}

.app-footer .support-channels {
    display: flex;
    margin: 8px 0;
}
.app-footer .support-channels a { 
    margin-right: 8px;
}
.app-footer .support-channels a img{
    width: 34px;
}

