.link-generation {
    margin-bottom: -40px;
}
.link-generation .code-preview{
    flex: 8;
}

.link-generation .code-viewer{
    flex: 16;
}


.link-generation .body {
    background-color: #f7f7f7;
    padding: 40px 0 60px 0;
}

.link-generation .body > section:not(:last-child) {
    margin-bottom: 40px;
}

.link-generation .shorten-url {
    padding: 40px 10px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(117, 119, 123, 0.1);
    
}
.link-generation .shorten-url-wrapper{
    display: flex;
    justify-content: center;
}
.link-generation .shorten-url .shorten-url-controls{
    display: inline-flex;
    align-items: center;
}


.link-generation .shorten-url .shorten-url-input{
    max-width: 420px;
    margin: 0 10px 0 0;
    
}
@media(max-width:767px){
    .link-generation .shorten-url-wrapper{
        flex-direction: column;
    }
    .link-generation .shorten-url .shorten-url-input{
        margin: 0;
    }
    .link-generation .shorten-url .shorten-url-controls{
        justify-content: center;
        margin-top: 10px;
    }
}
.shorten-url-controls-main{
    margin-left: 8px;
}

.link-generation .code-preview .no-padding {
    padding: 0;
}

.link-generation .code-preview .product-preview {
    margin-bottom: 30px;
}

.link-generation .code-viewer-container {
    display: flex;
    flex-direction: column;
}

