.card {
    &:global(.ant-card){
        transition: 150ms ease-in-out all;
    }
    &:global(.ant-card-hoverable:hover){
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    }
    :global(.ant-card-body){
        padding: 16px;
    }
    :global(.ant-card-meta-title) {
        font-weight: 700;
        font-size: 18px;
    }
    .cover{
        
        img{
            
            width: 100%;
        }
    }
    
}
.description{
    overflow: hidden;
    margin: 6px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #666;
}
.datetime{
    min-height: 18px;
    text-align: right;
    font-size: 12px;
}