.banner-generation .code-preview {
    flex: 14;
}
@media (max-width: 767px) {
    .banner-generation .code-preview .code-container > div {
        overflow: auto;
    }
}
.banner-generation .code-viewer {
    flex: 10;
}

.banner-generation .code-preview .image-container {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.banner-generation .code-viewer-container {
    display: flex;
    flex-direction: column;
}
