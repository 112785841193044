.video-banner-generation .code-preview {
    flex: 15;
}

.video-banner-generation .code-viewer {
    flex: 9;
}

.video-banner-generation .code-container iframe{
    max-width: 100%;
}
