.box {
  height: 100%;
  padding: 32px;
  border-radius: 8px;
  background: #fff;
  box-shadow: rgba(24, 24, 24, 0.09) 0px 0px 24px;

  
  &.topBox{
    margin:24px 0;
    :global(.metric-value){
      font-size: 30px;
    }
  }
  h3{
    font-size: 24px;
    font-weight: 600;
  }
  h4{
    
    font-size: 18px;
  }
}

.metricBox{
  line-height: 2.4;
  font-size: 16px;
  &.weighted{
    font-weight: 600;
  }
}

.container{
  display: flex;
  gap: 24px;
  > div {
    flex: 1;
  }
}
@media (max-width: 991px) {
  .container{
    flex-direction: column;
  }
}
