.cp-row {
    text-align: center;
}
.cp-row-content {
    text-align: left;
    /* display: inline-block; */
    /* width: 1440px; */
    /* width: 100%; */
    width: 100vw;
    margin: 0 auto;
    padding: 0px 20px;
}
@media (min-width:1440px){
    .cp-row-content{
        width: 1440px;
    }
    
}
@media (min-width: 768px){
    .cp-row-content{
        padding: 0 140px;
    }
}
.cp-row-content.no-padding {
    padding: 0px !important;
}
.cp-row-content.align-center {
    text-align: center;
}
.cp-row-content.position-relative {
    position: relative;
}

.cp-row .cp-row-loading .loading-mask {
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 998;
    text-align: center;
    display: block;
}

.cp-row .cp-row-loading.loading-mask .ant-spin {
    position: relative;
    top: 100px;
    color: white;
}

.cp-row .cp-row-loading.loading-mask .ant-spin .ant-spin-dot i {
    background-color: #008C00; 
} 