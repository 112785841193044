.dashboard {
    background-color: white;
}

.dashboard .ws-body {
    padding-top: 0px;
    padding-bottom: 0px;
}

.dashboard .main-area {
    padding: 30px 0px;
}

@media(min-width:576px){
    .dashboard .main-area {
        padding: 80px 0;
    }
}

.dashboard .search-title {
    margin-bottom: 37px;
}

.dashboard .search-box-container {
    background-color: #f7f7f7;
    border-radius: 8px;
    height: 96px;
    padding: 0 10px;
    line-height: 96px;
}

.dashboard .monthly-last-update {
    position: absolute;
    right: 0;
    top:0;
    line-height: 31px;
    vertical-align: text-bottom;
    font-size: 12px;
    color: #808286;
    text-align: right;
}

.dashboard .best-products-area {
    padding: 24px 0;
    padding-bottom: 50px;
    background-color: #f7f7f7;
}