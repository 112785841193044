.user-toolbar {
    font-size: 12px;
}
.user-info {
    display: inline-flex;
    align-items: center;
    margin: 0;
}

.user-info-in-dropdown:hover {
    cursor: default;
    background: none !important;
}
.affiliate-group-badge {
    margin-right: 4px;
}
.user-id {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    margin-right: 18px;
    line-height: 36px;
    /* color: #808686; */
}

.user-simulated {
    background-color: #ffb700;
}

/* .user-id span{
    font-weight: 700;
} */

.user-email {
    max-width: 183px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.user-toolbar .ant-dropdown-link {
    cursor: pointer;
    display: inline-block;
    color: #3c3e41;
}

.user-toolbar .user-toolbar-icon {
    vertical-align: middle;
    cursor: pointer;
}

/* .user-toolbar .user-icon:hover {
    color: #346aff;
} */

.user-toolbar .help {
    border: none;
    padding: 0;
    height: auto;
    font-weight: bold;
    color: #3c3d3f;
}

.user-toolbar .help.ant-btn-clicked:after {
    animation: none;
}

.user-status-indicator{
    cursor: pointer;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
}
.user-status-approved{
    background-color: #008C00;
}
.user-status-rejected{
    background-color: #f50;
}
.user-status-pending{
    background-color: #fd0
}
.user-status-default{
    background-color: #aaa;
}

@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;