.dt-input-filter-drop {
    padding: 15px;
    border: 1px solid #d3d3d3;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.dt-input-filter-drop .input-line {
    margin-bottom: 10px;
}

.dt-input-filter-drop .input-line .ant-input {
    width: 100%;
}

.dt-input-filter-drop .buttons {
    text-align: center;
}

.dt-input-filter-drop .buttons .ant-btn {
    padding: 0px 12px;
}

.dt-input-filter-drop .buttons button+button {
    margin-left: 12px;
}