.product-item {
    width: 132px;
    min-height: 225px;
    /* margin-bottom: 15px; */
    padding: 10px;
    box-sizing: border-box;
    /* border: 1px dashed silver; */
    transition: 150ms ease-in-out all;
    /* background: #eee; */
}
@media(min-width:360px){
    .product-item{
        width: 160px;
        min-height: 285px;
    }
}
@media(min-width:400px){
    .product-item{
        width: 172px;
    }
}


.product-item:not(.as-placeholder):hover {
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
}

.product-item .product-picture {
    /* border: 1px dotted sandybrown; */
    position: relative;

    .rank-no {
        position: absolute;
        top: -8px;
        left: -8px;
        padding: 4px 2px;
        min-width: 22px;
        background-color: red;
        color: white;
        text-align: center;
    }
}

.product-item .product-picture > img {
    height: 100%;
    width: 100%;
}

.product-item .hover-btn {
    width: 144px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -72px;
    margin-top: -20px;
    font-weight: bold;
    display: none;
}

.product-item .hover-btn.btn-open-detail {
    transform: translateY(-50%);
    margin-top: -25px;
}

.product-item .hover-btn.btn-generate-link {
    transform: translateY(50%);
    margin-top: -15px;
}

.product-item:hover .hover-btn {
    display: inline-block;
}
@media(max-width:768px){
    .product-item .hover-btn {
        width: 118px;
        height: 38px;
        margin-left: -59px;
    }
}

.product-item .hover-btn > svg {
    margin-right: 6px;
    vertical-align: middle;
}
.product-item .hover-btn > span {
    vertical-align: middle;
}

.product-item .icon-btn {
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #aeb0b4;
    text-align: center;
}
.product-item .icon-btn.btn-open-detail {
    margin-left: -24px;
}
.product-item .icon-btn.btn-generate-link {
    margin-left: 24px;
}
.product-item .icon-btn svg {
    margin-top: 12px;
    line-height: 1;
}


.product-item .product-meta {
    margin-top: 15px;
    min-height: 90px;
    padding: 6px;
    border-radius: 8px;
    cursor: default;
}

.product-item .product-meta:hover {
    background-color: #f7f7f7;    
}

.product-item .product-description {
    font-size: 12px;
    letter-spacing: -0.4px;
    line-height: 1.33;
    max-height: 2.6em;
    overflow: hidden;
    word-wrap: break-word; 
 /*   display: -webkit-box;
    -webkit-line-clamp: 2;    
    -webkit-box-orient: vertical;
    color: red; */
}

.product-item .product-price {
    padding-top: 6px;
    margin-right: -6px;
    line-height: 1.2;
}

.product-item .product-price .discount {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: #808286;
}

.product-item .product-price .discount .currency-label {
    text-decoration: line-through;
}

.product-item .product-price .sale-price {
    font-size: 18px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    letter-spacing: -0.8px;
    color: #111;
}

.product-item .product-price .sale-price .currency-code {
    font-size: 14px;
    font-family: NotoSansCJKkr, sans-serif;
    color: #3c3d3f;
}

.product-item .product-price .sale-price .delivery-badge {
    margin-left: 2px;
}

.product-item .sale-price .delivery-badge > img {
    height: 16px;
    display: inline-block;
}

.product-item {
    &:hover {
        .product-actions {
            display: flex;
        }
    }

    .product-actions {        
        display: none;
        position: absolute;
        flex-direction: column;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        button + button {
            margin-top: 15px;
        }        

        &.mobile {
            display: flex;
            flex-direction: row;
            transform: translateX(-50%) translateY(0);

            .product-action {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                width: 40px;
                background: #fff;
                border-radius: 50%;
                border: 1px solid #aeb0b4;
                text-align: center;

                &+.product-action {
                    margin-left: 15px;
                }

                &.disabled {
                    cursor: not-allowed;
                    background-color: #d3d3d3;
                }
            }
        }        
    }    
}

@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;