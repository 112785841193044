.ant-upload-select-picture-card i {
    font-size: 72px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}


.picture-card-uploader  .ant-upload-list-picture-card .ant-upload-list-item,
.picture-card-uploader .ant-upload.ant-upload-select-picture-card{
    width: 204px;
    height: 204px;
}

.picture-card-uploader .ant-upload-list-picture-card-container {
    float: none;
    width: auto;
    height: auto;
    margin: 0 0 8px 0;
}

.picture-uploader-modal .ant-modal-body {
    min-height: 300px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
