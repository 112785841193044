.link-to-any-page {
    text-align: left;
}

.link-to-any-page-banner {
    min-height: 120px;
    background-color: #e2f2f0;
    border-radius: 8px;
    margin-bottom: 40px;
}

.link-to-any-page .l2a-description {
    padding: 40px;
    font-weight: bold;
    font-size: 16px;
}

.link-to-any-page .l2a-description > p:not(:last-child) {
    margin-bottom: 10px;
}

.link-to-any-page .l2a-description > p:last-child {
    margin-bottom: 0px;
}

.link-to-any-page .l2a-image {
    overflow: hidden;
    align-self: flex-end;
    text-align: center;
    padding: 10px 40px 0px 0px;
}

.link-to-any-page .l2a-image > img {
    width: 100%;
    max-width: 560px;
}

@media (max-width: 992px) {
    .link-to-any-page .l2a-description {
        padding-bottom: 0px;
    }

    .link-to-any-page .l2a-image {
        padding: 10px 40px 0px 40px;
    }
}

.link-to-any-page .manual-link {
    margin-bottom: 12px;
}
.link-to-any-page .manual-link .ant-form-item {
    margin-bottom: 0;
}

.tracking-url {
    display: flex;
    margin-bottom: 12px;
    position: relative;
    .tracking-url-input {
        flex: 1;
        margin-right: 10px;
    }
    .tracking-url-controls {
        display: flex;
        justify-content: center;
        align-items: center;
        .tracking-url-controls-main {
            min-width: 90px;
            margin-left: 8px;
        }
    }
}

@media (max-width: 767px) {
    .tracking-url {
        flex-direction: column;

        .tracking-url-input {
            flex: 1;
            margin-right: 0;
        }
        .tracking-url-controls {
            margin-top: 10px;
        }
    }
}

.link-to-any-page .isolate-button .ant-input::placeholder {
    font-weight: 400;
}

.link-to-any-page .isolate-button .ant-btn {
    min-width: 90px;
}

.link-to-any-page .isolate-button.ant-input-affix-wrapper .ant-input {
    width: calc(100% - 100px);
    font-size: 14px;
}

.link-to-any-page .isolate-button.ant-input-affix-wrapper .ant-input-suffix {
    right: 0px;
}

.link-to-any-page .recommended-links {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.link-to-any-page .recommended-links > label {
    margin-right: 24px;
    color: #008C00;
    font-weight: bold;
    white-space: nowrap;
}

.link-to-any-page .link-tags .ant-btn {
    padding: 5px 15px;
    color: white;
    border-radius: 6px;
    font-size: 12px;
    border: 0px;
    height: auto;
    margin-bottom: 4px;
    margin-top: 4px;
}

.link-to-any-page .link-tags .ant-btn {
    background-color: #008C00;
}
.link-to-any-page .link-tags .ant-btn:not(:last-child) {
    margin-right: 10px;
}

@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;