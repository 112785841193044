.search-box.ant-input-affix-wrapper .ant-input-prefix {
    left: 3px;
    line-height: 38px;
}

.search-box.ant-input-affix-wrapper .ant-input-suffix {
    right: 0px;
}

.search-box .search-category {
    min-width: 118px;
}

.search-box .search-button {
    border-radius: 0;
    height: 40px;
    width: 56px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.search-box .search-button svg {
    vertical-align: text-bottom;
}

.search-box .ant-select-selection {
    height: auto !important;
    border: none;
}


.search-box .ant-input {
    border-radius: 8px;
}

.search-box.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 120px;
}

/* .search-box .ant-select-selection__rendered {
    margin-left: 0px;
} */

.search-box .ant-select-focused .ant-select-selection, 
.search-box .ant-select-open .ant-select-selection,
.search-box .ant-select-selection:focus, .ant-select-selection:active {
    border-color: transparent;
    box-shadow: none;
}


.search-box .ant-select {
    font-size: 12px;
    font-weight: bold;
    color: #3c3d3f;
}

.ant-select-dropdown.search-category {
    font-size: 12px;
}

.search-box .ant-input-affix-wrapper .ant-input-prefix :not(.anticon){
    line-height: 30px;
}

@media(max-width: 576px) {
    .search-box .search-category {
        min-width: 90px;
    }

    .search-box.ant-input-affix-wrapper .ant-input:not(:first-child) {
        padding-left: 92px;
    }
}