
.slick-dots li button:before {
    font-family: Arial, Helvetica, sans-serif;
}

.slick-next:before,
.slick-prev:before {
    font-family: Arial, Helvetica, sans-serif;
}

.focus-slider {
    width: 100vw;
    transform: translate3d(-50%, 0, 0);
    .slick-slide {
        overflow: hidden;
        text-align: center;
        img {
            position: relative;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
        }
        > div > div{
            display: block !important;
        }
    }
    &.slick-vertical {
        .slick-slide {
            border: 0 none;
        }
    }
    .slick-dots {
        bottom: 8px;
    }
}
@media (max-width: 991px) {
    .focus-slider {
        .slick-slide {
            img {
                width: 100%;
            }
        }
    }
}
.focus-slider-dots {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-100%, -50%, 0);

    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.3);
    a {
        display: block;
        background: #fff;

        border: 1px solid transparent;
        &.active {
            border-color: #4285f4;
        }
    }
}
@media (max-width: 991px) {
    .focus-slider-dots {
        left: 0;
        top: auto;
        bottom: 8px;
        transform: translate3d(-50%, 0, 0);

        box-shadow: none;
        a {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin: 4px;
            border-radius: 100%;
            opacity: 0.4;

            &.active {
                opacity: 1;
                border-color: transparent;
            }
        }
    }
}

@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;