.best-11-products .header {
    /* padding: 0px 10px; */
}

.best-11-products .header > h2 {
    float: left;
    line-height: 36px;
    font-size: 21px;
}

.best-11-products .best-products-categories {
    float: right;
    border-color: #808286;
}

.best-11-products .best-products-categories .ant-select-selection--single {
    height: 36px;
}

.best-11-products .best-products-categories .ant-select-selection--single .ant-select-selection__rendered {
    line-height: 36px;
}

.best-11-products .header::after {
    content: '';
    display: table;
    clear: both;
}