.ws-page {
    flex: auto;
    display: flex;
    flex-flow: column;
    background-color: white;
}

.ws-header {
    padding-top: 30px;
    .ws-header-bar {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }
}
@media (max-width: 575px) {
    .ws-header {
        .ws-header-bar {
            flex-direction: column;
        }
    }
}

@media (min-width: 576px) {
    .ws-header {
        padding-top: 40px;
    }
}

.ws-header.hidden {
    display: none;
}

.ws-breadcrumb.ant-breadcrumb,
.ws-header-bar {
    font-size: 12px;
    color: #808286;
}

.ws-breadcrumb .breadcrumb-icon {
    vertical-align: middle;
}

.ws-breadcrumb svg {
    vertical-align: text-top;
    color: #cfd0d2;
}

.ws-breadcrumb > span:last-child > a {
    color: #346aff;
}

.ws-breadcrumb .ant-breadcrumb-link {
    cursor: default;
}

.ws-breadcrumb .ant-breadcrumb-link a {
    color: #808286;
}

.ws-breadcrumb .ant-breadcrumb-link[href] {
    cursor: pointer;
}

.ws-title {
    display: flex;
    flex-direction: column;
    margin: 0;
    font-size: 21px;
    &.flex-direction-row {
        flex-direction: row;
        justify-content: space-between;

        .ws-action-container {
            justify-content: flex-end;
        }
    }
}

.ws-title .ws-help-link {
    position: absolute;
    right: 0px;
    bottom: -30px;
    font-size: 14px;
    font-weight: normal;
    color: #727272;
    z-index: 9;
}

.ws-title .ws-help-link a {
    color: #008c00;
}

@media (min-width: 576px) {
    .ws-title {
        flex-direction: row;
        font-size: 44px;
    }
    .ws-action-container {
        justify-content: flex-end;
    }
}

.ws-title .sub-title {
    font-size: 14px;
    color: #008c00;
    margin-left: 1em;
    font-weight: 500;
}

@media (min-width: 576px) {
    .ws-title .sub-title {
        font-size: 21px;
    }
}
.ws-title-tips {
    z-index: 3;
    position: relative;
    top: 8px;
    color: #008c00;
    font-size: 14px;
}
.ws-title-tips a,
.sub-title a {
    cursor: pointer;
    color: #008c00 !important;
    text-decoration: underline;
}
.ws-title-tips svg {
    margin-bottom: -4px;
}

@media (min-width: 576px) {
    .ws-action-container {
        justify-content: flex-end;
    }
}

.ws-action-container {
    flex: 1;
    display: flex;
    align-items: flex-end;
    margin: 10px 0;
    color: #808286;
    font-size: 12px;
    font-weight: normal;
}

.ws-action-group {
    display: flex;
    align-items: center;
}

.ws-action:not(:last-child) {
    margin-right: 12px;
}

.ws-body {
    display: flex;
    flex-direction: column;
    flex: auto;
    padding: 20px 0px;
    position: relative;
    // margin: auto;
    /* min-height: 440px; */
}

@media (min-width: 576px) {
    .ws-body {
        padding: 40px 0;
    }
}
.ws-page {
    &.mini {
        .ws-body {
            padding: 0;
        }
    }
}

.ws-body .loading-mask {
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 998;
    text-align: center;
    display: block;
}

.ws-body .loading-mask .ant-spin {
    position: relative;
    top: 100px;
    color: white;
}

.ws-body .loading-mask .ant-spin .ant-spin-dot i {
    background-color: #008c00;
}

.ws-fab {
    z-index: 999;
    position: fixed;
    bottom: 40px;
    right: 40px;
    .ws-fab-button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 2px 8px 0 rgba(119, 119, 123, 0.24);
    }
}

@media (max-width: 575px) {
    .ws-fab {
        right: 24px;
        .ws-fab-button {
            width: 40px;
            height: 40px;
        }
    }
}

@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;