.best-products .best-products-categories {
    width: 168px;
    position: absolute;
    top: -74px;
    right: 0px;
    border-color: #808286;
}

@media(min-width:576px){
    .best-products .best-products-categories {
        top: -90px;        
    }
}


.best-products .best-products-categories .ant-select-selection--single {
    height: 36px;
}

.best-products .best-products-categories .ant-select-selection--single .ant-select-selection__rendered {
    line-height: 36px;
}

.best-products .ant-spin-blur{
    overflow: visible;
}
