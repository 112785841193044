.custom-dataset-dropdown-menu.ant-select-dropdown {
    z-index: 999;   // Must be less than Modal which is 1000

    .dataset-item {
        display: flex;
        justify-content: space-between;         
    }

    .dataset-item-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;    
    }

    .dataset-item-actions {
        margin-left: 1em;
        font-size: 16px;
        .anticon + .anticon {
            margin-left: 10px;
        }
    }
}
@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;