.box{
  margin-bottom:56px;
  padding:24px;
  border-radius:8px;
  // box-shadow: 0px -1px 0px 2px rgba(0,0,0,0.1);
  // box-shadow: 0px 1px 0px 2px rgba(0,0,0,0.1);
  box-shadow: 0px 2px 1px 3px rgba(0,0,0,0.075);
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px, rgba(0, 0, 0, 0.1) 0px -4px 0px inset;
  line-height: 1.6;
  .value{
    font-weight: 700;
  }
}