
.date-ranged-report-predefined-ragnes {
    margin-bottom: 16px;
}
.date-ranged-report-help{
    margin-top: 24px;
    color: #808286;
    font-size: 12px;
}
.data-ranged-report-text {
    margin-left: 5px;
    color: #008C00;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
}