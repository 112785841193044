.company-modification-tips .notice {
    margin-bottom: 1em;
    font-weight: bold;
}

.company-modification-tips .notice svg {
    vertical-align: bottom;
}

.company-modification-tips .notice .anticon {
    font-size: 20px;
}

.company-modification-tips .ant-alert {
    font-size: 12px;
}

.company-modification-tips .ant-alert svg {
    vertical-align: text-bottom;
}

.company-modification-tips .ant-alert.ant-alert-success {
    background-color: #e4f4ea;
    border: none;
    border-radius: 4px;
}

.company-modification-tips .ant-alert:not(:last-child) {
    margin-bottom: 6px;
}

.company-modification-tips .ant-alert .ant-alert-message > div {
    display: table;
}

.company-modification-tips .ant-alert .ant-alert-message > div > span {
    display: table-cell;
    vertical-align: middle;
}

.company-modification-tips .ant-alert .sequence {
    padding-right: 1em;
    font-size: 14px;
}
  