.profile .tip {
    font-size: 14px;
    font-weight: 300;
    margin-top: 3px;
    margin-bottom: 24px;
}

.profile .affiliate-type {
    margin-bottom: 8px;
}

.profile .tooltip-question {
    vertical-align: text-bottom;
}

.profile .affiliate-type .ant-radio-group > .ant-radio-wrapper:last-child {
    margin-right: 0px;
}

.profile .field-group {
    border-left: 3px solid #008C00;
    padding-left: 12px;
}

.profile .links {
    color: #3c3d3f;
}

.profile .links > a {
    color: #3c3d3f;
    text-decoration: underline;
}
