.report-download-tasks {
    .job-status {
        padding: 2px 5px;
        border-radius: 4px;        
    }
    .job-status-SUCCEED {
        background-color: #008C00;
        color: white;
    }
    .job-status-FAILED {
        background-color: #f56112;
        color: white;
    }
}
@font-family: NotoSansCJKKr, -apple-system, Roboto, Helvetica, Helvetica, Arial, sans-serif;@text-color: #3c3d3f;@radio-dot-color: #346aff;@checkbox-color: #346aff;