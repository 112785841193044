.affiliateTypeRadioGroup{
    display: flex;
    justify-content: space-between;
    :global(.ant-radio-wrapper){
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }
}
@media(max-width: 576px){
    .affiliateTypeRadioGroup{
        flex-direction: column;
        :global(.ant-radio-wrapper:last-child){
            margin-bottom: 0;
        }
    }
}