.video-banner-list .video-banner-item {
    display: block;
    margin-bottom: 24px;
    background: #fff;
    text-align: center;
    color: inherit;
    transition: box-shadow 400ms ease;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
}
.video-banner-list .video-banner-item:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px;
}
.video-banner-list .video-banner-item .poster {
    max-width: 100%;
}
.video-banner-list .video-banner-item .title {
    overflow: hidden;
    padding: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
