.consent-detail-modal .ant-modal-close-x {
    height: 68px;
    width: 68px;
    line-height: 68px;
}

/* .consent-detail-modal .ant-modal-close-x:before {
    content: "\E62F";
    font-size: 18px;
} */

.consent-detail-modal .ant-modal-close:focus, .consent-detail-modal  .ant-modal-close:hover {
    color: #aeb0b4;
}