.checkable-tags-input .checkable-tags-input-tags {
    margin-bottom: 0.5em;
}

.checkable-tags-input .checkable-tags-input-tags .ant-tag {
    padding: 5px 10px;
    display: inline-block;
    height: auto;
    border-radius: 8px;
    margin-bottom: 0.5em;
}

.checkable-tags-input .checkable-tags-input-tags .ant-tag-checkable {
    background-color: #f7f7f7;
}

.checkable-tags-input .checkable-tags-input-tags .ant-tag-checkable-checked {
    background-color: #008C00;
    font-weight: 500;
}

.checkable-tags-input .checkable-tags-input-container .btn-add {
    font-size: 12px; 
    font-weight: bold;
    padding: 0px 13px;
}

.checkable-tags-input .ant-tag .anticon.remove-tag {
    vertical-align: text-bottom;
    margin-left: 1em;
    font-size: 16px;
    font-weight: bold;
}

.checkable-tags-input .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 70px;
}