.product-discovery-search .ft-sub-headline-bold.link-tip {
    margin-bottom: 10px;
    line-height: 32px;
}

.product-discovery-search .ft-sub-headline-bold.link-tip button.btn-sample-create-link {
    vertical-align: top;
    width: 116px;
    font-weight: bold;
}

.product-discovery-search .ft-sub-headline-bold.link-tip button.btn-sample-create-link svg {
    vertical-align: text-bottom;
}

.product-discovery-search section:not(:last-child) {
    margin-bottom: 20px;
}

.product-discovery-search .cp-steps.ant-steps-dot {
    margin-bottom: 6px;
}

.product-discovery-search .search-bar {
    padding: 28px 10px;
    border-radius: 8px;
    background-color: #f7f7f7;
}

.product-discovery-search .srp-buttons {
    display: inline-flex;
}

.product-discovery-search .srp-buttons .ant-btn+.ant-btn {
    margin-left: 1em;
}

.product-discovery-search .srp-buttons .ant-btn {
   display: inline-flex;
   align-items: center;
}

.product-discovery-search .srp-buttons .ant-btn:not(.ant-btn-circle) svg {
    margin-right: 0.5em;
}

.product-discovery-search .srp-buttons .ant-btn-circle {
    height: 36px;
    width: 36px;
    justify-content: center;
}


.product-discovery-search .link-caution img {
    width: 100%;
}


@media (min-width:992px){
    .product-discovery-search .link-caution {
        display: block;
    }
}

@media (max-width:991px){
    .product-discovery-search .link-caution.link-caution-mobile {
        display: block;
    }
}

.product-discovery-search .search-result-header .search-result-total {
    /* float: left; */
    font-weight: 300;
}

.product-discovery-search .search-result-header .search-result-total > strong {
    font-weight: bold;
}

.product-discovery-search .search-result-header .search-result-total::after {
    content: '|';
    color: #ECEDEE;
    padding: 2px 10px;
}

.product-discovery-search .search-result-header .dim {
    color: #808286;
    font-weight: 300;
}
.product-discovery-search .delivery-filter  {
    text-align: right;
}
@media (max-width:575px){
    .product-discovery-search .delivery-filter  {
        text-align: left;
    }
}

.product-discovery-search .delivery-filter img {
    height: 16px;
}
.product-discovery-search .delivery-filter img {
    height: 16px;
}

.product-discovery-search .best-11-products-container {
    margin: 50px 0px -20px 0px;
    padding: 24px 0px 30px 0;
    background-color: #f7f7f7;
}
@media (min-width:576px){
    .product-discovery-search .best-11-products-container {
        margin: 100px 0px -40px 0px;
        padding: 24px 0px 60px 0;
    }
}